import type { Callback } from '~/interfaces';

const callbackListenerDelay = 400;

export default function useCallbackOnBlur(callback: Callback | (() => false | undefined)) {
  const removeListener = () => {
    const remove = callback() === undefined;
    if (remove) window.removeEventListener('click', removeListener);
  };

  const addListener = () => {
    setTimeout(() => window.addEventListener('click', removeListener), callbackListenerDelay);
  };

  return {
    removeListener,
    addListener,
  };
}
